<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">Расшифровка. {{ currCategory.name_ru }}</p>
            </div>
            <div class="right-content">
                <template v-if="categoryMode">
                    <div class="filter-actions">
                        <c-budget-forms-copy-data 
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            :total="total"
                            @keyPress="keyPress"
                        />
                    </div>
                </template>
                <template v-else>
                        <b-button variant="light" @click="resetModal">
                           <i class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                        </b-button>
                        <b-button v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                            <i class="icon icon-plus-circle"></i>Добавить
                        </b-button>
                        <b-button
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="prepareForSave">
                            Сохранить
                        </b-button>
                    </template>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                        title="Удалить выбранные записи"
                        class="icon icon-close table-all-remove"
                        @click="deleteItem(`${selectAll ? 'всех' : 'выбранных'} категорий`)"/>
                    </div>
                </template>
                <template #top-row="data">
                    <td colspan="2"></td>
                    <td  class="top-row-td">
                        <tr class="td-numbering table-success top-row-tr1">1</tr>
                        <tr class="top-row-tr2"></tr>
                    </td>
                    <td  class="top-row-td">
                        <tr class="td-numbering table-danger top-row-tr1">2</tr>
                        <tr class="top-row-tr2"></tr>
                    </td>
                    <td  class="top-row-td">
                        <tr class="td-numbering table-info top-row-tr1">3</tr>
                        <tr class="top-row-tr2"></tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-primary top-row-tr1">4</tr>
                        <tr class="top-row-tr2">(тенге)</tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-warning top-row-tr1">5</tr>
                        <tr class="top-row-tr2">(квадратный метр)</tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-success top-row-tr1">6</tr>
                        <tr class="top-row-tr2">(тенге)</tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-danger top-row-tr1">7</tr>
                        <tr class="top-row-tr2"></tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-primary top-row-tr1">8</tr>
                        <tr class="top-row-tr2">(тысяч тенге)</tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-info top-row-tr1">9</tr>
                        <tr class="top-row-tr2">(тысяч тенге)</tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-warning top-row-tr1">10</tr>
                        <tr class="top-row-tr2">(тысяч тенге)</tr>
                    </td>
                    <td class="top-row-td">
                        <tr class="td-numbering table-success top-row-tr1">11</tr>
                        <tr class="top-row-tr2"></tr>
                    </td>
                    <td class="td-numbering"></td>
                </template>

                <template #cell(selection)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button class="table-plus"
                        v-b-modal.goods-modal :disabled="monthUpdating" 
                        @click="setCurrCatName(data.item)" >
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(name_ru)="data">
                    <div>
                        {{ data.item.name_ru }}
                    </div>
                </template>
                <template #cell(unit)="data">
                    <div>
                        {{ data.item.unit }}
                    </div>
                </template>
                <template #cell(amount)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(cost_avg)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(area)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(cost_sqm)="data">
                    <div  v-if="data.item.code === '10'" class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  v-model.number="data.item.months"
                                  @keyup.enter.exact="keyup13"
                                  @keyup="checkNumber($event, 1, 12)"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'func_day', data.item.func_day)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost_year)="data">
                    <div class="text-right" v-if="data.item.code === '10'">{{ $n(data.value) }}</div>
                </template>
                <template #cell(cost_cur)="data">
                    <div class="text-right" v-if="data.item.code !== '10'">{{ $n(data.value) }}</div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick(data.item)"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove" v-if="variantAttribute" :disabled="data.item.total <= 0" @click="deleteItem('данной категории', data.item, data.index)"></i>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="9">ИТОГО</td>
                    <td class="text-right">{{ $n(cost_year) }}</td>
                    <td class="text-right">{{ $n(cost_cur) }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-template3
                    ref="formTemplate"
                    :formCode="form.code"
                    :initialGoodsData="initialGoodsData"
                    :goodsData="goodsData"
                    :ensTruDict="ensTruDict"
                    :variantAttribute="variantAttribute"
                    :currCategory="currCategory"
                    :lang="lang"
                    :ifAllFieldsFilled="ifAllFieldsFilled"
                    :deleteGood="deleteGood"
                    :getUnitName="getUnitName"
                    :ensTruNamePrt="ensTruNamePrt"
                    :ensTruName="ensTruName"
                    :currUnits="currUnits"
                    :descIsLoading="descIsLoading"
                    :parNameLoading="parNameLoading"
                    :allFiles="allFiles"
                    @setTotals="setTotals"
                    @updateDatas="updateDatas"
                    @save="save"
                    @onDataChanged="onDataChanged"
                    @searchName="searchName"
                    @setIsAllDelete="setIsAllDelete"
                    @deleteSeveralGoods="deleteSeveralGoods"
                    :triggDownloadFile="triggDownloadFile"
                    :showFileUploadModal="showFileUploadModal"
                    :getEnstruByName="getEnstruByName"
                    :loadCurUnits="loadCurUnits"
                    :goodsAreSaving="goodsAreSaving"
                >
                </form-template3>
                <div class="table-add" v-if="variantAttribute" >
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> Добавить</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
// import BudgetAttachFile from "./components/budget-attach-file";
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import FormTemplate3 from "./components/budget-forms-template3.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_159',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, FormTemplate3, ModalAllFilesManagement, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '01-159',
                name_ru: 'Расчет расходов по содержанию, обслуживанию, текущему ремонту зданий, помещений, оборудования и других основных средств',
                name_kk: 'Ғимараттарды, үй-жайларды, жабдықтарды және басқа да құралдарды ұстау, қызмет көрсету, ағымдағы жөндеу бойынша шығыстарды есептеу'
            },
            tableFields: [
                {
                    key: 'selection',
                    label: ' '
                },
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Наименование'
                },
                {
                    key: 'unit',
                    label: 'Единица измерения'
                },
                {
                    key: 'amount',
                    label: 'Кол-во'
                },
                {
                    key: 'cost_avg',
                    label: 'Средняя стоимость услуг за ед. оборудования в месяц'
                },
                {
                    key: 'area',
                    label: 'Занимаемая площадь'
                },
                {
                    key: 'cost_sqm',
                    label: 'Сумма расходов на 1 кв.м. в месяц'
                },
                {
                    key: 'months',
                    label: 'Количество месяцев'
                },
                {
                    key: 'cost_year',
                    label: 'Сумма расходов в год (гр.3 х гр.4+гр.5 х гр.6) х гр.7/1000'
                },
                {
                    key: 'cost_cur',
                    label: 'Сумма расходов в год на текущий ремонт'
                },
                {
                    key: 'total',
                    label: 'Общая сумма расходов (гр.8 + гр.9)'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            monthUpdating: false,
        };
    },

    mounted() {
        this.progress = 15;
    },

    methods: {

        deleteItem(msg, row = false, index = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.currCategory = row;
            this.$bvModal.msgBoxConfirm(
                `Будут удалены все вложенные товары и услуги. Подтвердите очистку ${msg}...`,
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(async value => {
                    if (value) {
                        this.deletingCat = true;
                        if (row) {
                            if (this.currCategory) {
                                await this.loadGoodsData(this.currCategory);
                            }
                            process.nextTick(() => {
                                if (row.id >= 0) {
                                    this.deleteGood(this.goodsData, true, true);
                                    this.itemUpdate(row);
                                } else {
                                    this.budgetForm.splice(index, 1);
                                }
                            });
                        } else {
                            const existingRowsToDel = this.budgetForm.filter(item => item.id >= 0 && item.itemToDelete);
                            // this.budgetForm = this.budgetForm.filter(item => !item.itemToDelete);
                            if (!existingRowsToDel.length) { 
                                this.selectAll = false;
                            } else {
                                await this.deleteSeveralCats(existingRowsToDel);
                            }
                        }
                    }
                })
                .catch(error => {
                    this.makeToast('danger', 'Ошибка удаления', error.toString());
                })
                .finally(() => {
                    this.deletingCat = false;      
                })

        },

        prepareData(item) {
            this.$set(item, 'good_type', item.repair);
            this.$set(item, 'price', item.cost_avg);
            this.setPassVals(item);
        },

        ifAllFieldsFilled() {
            const prevNewItemIdx = this.goodsData.findIndex(item => item.isNew === true);
            if (prevNewItemIdx !== -1) {
                const prevNewItem = this.goodsData[prevNewItemIdx];
                if (!prevNewItem.enstru_code) {
                    this.makeToast('warning', 'Внимание', `Наименование не выбрано`);
                    return false;
                };
            }
            if (this.goodsData.findIndex(item => item.amountPassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Количество или площадь должны быть больше нуля`);
                return false;
            }
            if (this.goodsData.findIndex(item => item.pricePassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Средняя  стоимость должна быть больше нуля`);
                return false;
            }
            if (this.goodsData.findIndex(item => item.costsqmPassVal === false) !== -1) {
                this.makeToast('warning', 'Внимание', `Расходы на 1 кв.м. должны быть больше нуля`);
                return false;
            }
            if (prevNewItemIdx !== -1) this.$set(this.goodsData[prevNewItemIdx], 'isNew', false);
            return true
        },

        fillDataForSave(item, row) {
            this.$set(item, 'repair', this.currCategory.code);
            this.$set(item, 'months', this.currCategory.months);
            this.$set(item, 'amount', row.amount ? parseInt(row.amount) : 0);
            this.$set(item, 'cost_avg', row.price ? parseFloat(row.price) : 0);
            this.$set(item, 'area', row.area ? parseFloat(row.area) : 0);
            this.$set(item, 'cost_sqm', row.cost_sqm ? parseFloat(row.cost_sqm) : 0);
            this.$set(item, 'cost_cur', row.cost_cur ? parseFloat(row.cost_cur) : 0);



        }, // заполнение полей при подготовке к сохранению товаров

        onDataChanged(item) {
            this.$set(item, "rowToSave", true)
            this.dataChanged = true;
            this.setPassVals(item);
            // if (!item.amountPassVal || !item.pricePassVal || !item.costsqmPassVal) this.dataChanged = false;
            if (!item.amount || item.amount <=0) this.$set(item, 'price', 0);
            if (!item.area || item.area <=0) this.$set(item, 'cost_sqm', 0);
        }, // переключения тригера при изменении данных товаров

        setPassVals(item) {
            this.$set(item, "amountPassVal", !((!item.amount || item.amount <= 0) && (!item.area || item.area <= 0)));
            this.$set(item, "pricePassVal", !(item.amount && item.amount > 0 && (!item.price || item.price <= 0)));
            this.$set(item, "costsqmPassVal", !(item.area && item.area > 0 && (!item.cost_sqm || item.cost_sqm <= 0)));
        },

        getFiles(data) {
            this.files = data;
        },

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        input12(item, field, value) {
            if (parseInt(value) > 12) {
                this.$set(item, field, 0);
                this.makeToast('danger', 'Предупреждение', 'Требуется ввести число <= 12');
            } else {
                this.$set(item, field, value);
            }
        }, // проверка value>12

        inputFixed(item, field, value) {
            if (!value) {
                value = 1;
                this.$set(item, field, parseInt(value));
            }
            this.saveCat(item);
        }, // форматирует введенное значение до digit цифр после запятой

        async saveCat(item) {
            if (item.total === 0 || item.months === item.prevMonths) return;
            this.monthUpdating = true;
            this.currCategory = item;
            await this.loadGoodsData(item);
            process.nextTick(async () => {
                this.goodsData.forEach(good => {
                    this.$set(good, 'months', item.months);
                })
                this.save(this.goodsData, true, false, false);
                process.nextTick(async () => this.monthUpdating = false)
            })
        },

        itemUpdate(item) {
            this.$set(item, 'amount', 0);
            this.$set(item, 'cost_avg', 0.00);
            this.$set(item, 'area', 0.00);
            this.$set(item, 'cost_sqm', 0.00);
            this.$set(item, 'months', 12);
            this.$set(item, 'prevMonths', 12);
            this.$set(item, 'cost_cur', 0.00);
            this.$set(item, 'cost_year', 0.00);
            this.$set(item, 'total', 0.00);
            this.$set(item, 'newCat', true);
            this.$set(item, 'nameUpdating', false);
            this.$set(item, 'checkForDuplicates', false);
            this.$set(item, 'files', []);
            this.$set(item, 'itemToDelete', false);
        },

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadCategoryData() {
            this.load = true;
            await this.loadDict();
            const newBForm = [];
            for (const row of this.dict) {
                const item = {
                    id: 0,
                    id_dict: row.id,
                    code: row.code,
                    name_ru: row.name_ru,
                    unit: row.unit,
                    itemToDelete: false,
                };
                this.itemUpdate(item);
                newBForm.push(item);
            }
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                values = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryData()', error.toString());
            }
            newBForm.forEach(item => {

                const currSums = values.find(val => val.repair === item.code);
                if (currSums) {
                    this.$set(item, 'amount', currSums.sum_amount);
                    this.$set(item, 'cost_avg', currSums.sum_avg_costs_itm);
                    this.$set(item, 'area', currSums.taked_up_area);
                    this.$set(item, 'cost_sqm', currSums.sum_costs_per_month);
                    this.$set(item, 'months', currSums.months);
                    this.$set(item, 'prevMonths', currSums.months);
                    this.$set(item, 'cost_year', parseFloat((currSums.sum_costs_year / 1000).toFixed(3)));
                    this.$set(item, 'cost_cur', parseFloat((currSums.costs_year_rep / 1000).toFixed(3)));
                    this.$set(item, 'total', item.cost_year + item.cost_cur);
                    this.$set(item, 'files', this.getUnicFilesArr(currSums));
                } else {
                    this.$set(item, 'months', 12);
                    this.$set(item, 'prevMonths', 1);
                    this.$set(item, 'files', []);
                }
            })
            this.budgetForm = newBForm;
            this.load = false;
        },

        async loadDict() {
            try {
                const response = await fetch('/api-py/dictionary/repairs/');
                this.dict = await response.json();
                this.dict.sort((a, b) => a.code < b.code ? 1 : 0);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict()', error.toString());
            }
        },

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) {
                s = '0' + s;
            }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        getCodeFieldName() {
            return 'code';
        }, // при необходимости заменить в родителе

        // пересчет тотала при удалении нескольких записей
        calcTotalBeforeDel() {
            return this.budgetForm.length ? this.budgetForm.reduce((acc, row) => row.itemToDelete ? acc : acc + row.total, 0) : 0;
        },
    },

    computed: {
        cost_year() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_year);
            }
            return parseFloat((Math.round(sum * 1000) / 1000).toFixed(3));
        },
        cost_cur() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.cost_cur);
            }
            return parseFloat((Math.round(sum * 1000) / 1000).toFixed(3));
        },
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        }
    }
};
</script>

<style scoped>
.top-row-td {
    padding: 0;
}

.top-row-tr1 {
    width: 100%;
    height: 100%;
    display: block;
    line-height: 226%;
    border-bottom: 1px solid #d9dfe6;
    text-align: center;

}

.top-row-tr2 {
    text-align: center;
    display: block;
    height: 22px;
    font-size: 0.6rem;
    line-height: 22px;
    font-style: italic;
    color: #6589A0;
}
.table-plus {
    border: none;
}
</style>